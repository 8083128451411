
import {
	computed,
	defineComponent,
	ref,
	onMounted,
	onUpdated,
	onBeforeUnmount,
	nextTick,
	watch
} from 'vue'
import BScroll from '@better-scroll/core'
import MouseWheel from '@better-scroll/mouse-wheel'
import { useStore } from '@/store'
import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'
import logger from '@evideo/logger'
import useTurnPageSlide from './hooks/useTurnPageSlide'
import { Slide } from '@/types/slides'
import _ from 'lodash'
export default defineComponent({
	name: 'slide-thumbnails',
	components: {
		ThumbnailSlide
	},
	setup() {
		const store = useStore()
		const { turnSlideToIndex } = useTurnPageSlide()
		const slides = computed(() => store.state.slides)
		const slideIndex = computed(() => store.state.slideIndex)
		const activeThumbnailSlide = ref<HTMLElement>()
		const screenType = computed(() => store.state.screenType)

		const scroll = ref<any>()
		const scrollEl = ref<HTMLDivElement>()
		const totalSlideNum = computed(() => store.state.slides.length)
		const curSlideNum = computed(() => store.state.slideIndex + 1)

		const isImportPptx = ref(true)

		const initScroll = () => {
			if (screenType.value === 'preview') {
				scroll.value = new BScroll(scrollEl.value!, {
					scrollX: true,
					click: true,
					disableMouse: false,
					disableTouch: false,
					observeDOM: true // 修复移动设备无法拖拽
				})
			} else if (screenType.value === 'demonstrate') {
				scroll.value = new BScroll(scrollEl.value!, {
					mouseWheel: {
						speed: 20,
						invert: false,
						easeTime: 300
					},
					scrollY: true,
					click: true,
					disableMouse: false,
					disableTouch: false,
					observeDOM: true // 修复移动设备无法拖拽
				})
			}
			try {
				nextTick().then(() => {
					scroll.value && scroll.value.refresh()
					scroll.value &&
						scroll.value.scrollToElement(activeThumbnailSlide.value)
				})
			} catch (e) {
				logger.warn('预览图滚动到指定元素失败', e)
			}
			observeDOM.value = new MutationObserver(() => {
				scroll.value && scroll.value.refresh()
			})
			observeDOM.value &&
				observeDOM.value.observe(scrollEl.value, {
					childList: true,
					subtree: true
				})
		}
		const observeDOM = ref()
		const cancelClick = (event: MouseEvent) => {
			const rect = (event.target as HTMLElement).getBoundingClientRect()
			if (event.clientX >= rect.right - 50 && event.clientY <= rect.top + 50) {
				console.log('Pseudo-element was clicked!')
			}
		}

		const initThumbnails = () => {
			if (!store.state.pptUrl) {
				isImportPptx.value = false
				return
			}
			const slides = _.cloneDeep(store.state.slides)
      const slidesNum = slides.length
      // 导入的ppt，slide.previewSize === undefined
			const noPreviewedNum =
				slides.filter((slide: Slide) => slide?.previewSize === undefined)?.length || 0
			if (noPreviewedNum > 0) {
				logger.info(
					`该份ppt为导入的ppt，需要生成预览图 noPreviewedNum:${noPreviewedNum}; slidesNum :${slidesNum }`
				)
				isImportPptx.value = true
			} else {
				isImportPptx.value = false
      }
		}

		watch(
			() => store.state.pptUrl,
      () => {
				if (store.state.pptUrl) {
					initThumbnails()
				}
			}
		)

		watch(
			() => store.state.pageLoading,
			(newVal) => {
				if (!newVal && isImportPptx.value) {
          isImportPptx.value = false
				}
			}
		)

    onMounted(() => {
			initThumbnails()
			BScroll.use(MouseWheel)
			// fixme: 因为有外部的div会切换，所以延迟300ms，等待外部切换完后，初始化
			setTimeout(initScroll, 300)
		})
		onBeforeUnmount(() => {
			scroll.value && scroll.value.destroy()
			scroll.value = null
			observeDOM.value && observeDOM.value.disconnect()
		})

		onUpdated(() => {
			try {
				scroll.value && scroll.value.refresh()
				scroll.value &&
					scroll.value.scrollToElement(activeThumbnailSlide.value, 300, true)
			} catch (e) {
				logger.warn('预览图滚动到指定元素失败', e)
			}
		})

		return {
			slides,
			slideIndex,
			activeThumbnailSlide,
			screenType,
			scrollEl,
			turnSlideToIndex,
			cancelClick,
			curSlideNum,
			totalSlideNum,
      isImportPptx,
		}
	}
})
