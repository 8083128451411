
import _ from 'lodash'
import {
	computed,
	defineComponent,
	nextTick,
	onBeforeUnmount,
	onMounted,
	onUpdated,
	provide,
	ref,
	reactive
} from 'vue'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import { KEYS } from '@/configs/hotkey'
import useScreening from '@/hooks/useScreening'
import { useStore } from '@/store'
import { Slide } from '@/types/slides'
import ScreenSlide from '../Screen/ScreenSlide.vue'
import PageButton from './PageButton.vue'
import RoundButton from './RoundButton.vue'
import SlideThumbnails from './SlideThumbnails.vue'
import PreviewTabs from './PreviewTabs.vue'
import useDynamicTabs from '@/hooks/useDynamicTabs'
import useGlobalPencil from '@/hooks/useGlobalPencil'
import useGlobalPencilNext from '@/hooks/useGlobalPencilNext'
// import useJsBridge from '@/hooks/useJsBridge'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useQianKun from '@/hooks/useQianKun'
import useQianKunEventCenter from '@/hooks/useQianKunEventCenter'
import useRunTime from '@/hooks/useRunTime'
import usePagingScrren from './hooks/usePagingScrren'
import useIpcRenderer from '@/hooks/useIpcRenderer'
import useTurnPageSlide from './hooks/useTurnPageSlide'

import logger from '@evideo/logger'
import { SLIDE_ANIMATIONS } from '@/configs/animation'
import useTeachingTabPage from '@/hooks/useTeachingTabPage'
import useGlobalHotkey from '@/hooks/useGlobalHotkey'

export default defineComponent({
	name: 'evscreen',
	components: {
		ScreenSlide,
		SlideThumbnails,
		PageButton,
		RoundButton,
		PreviewTabs
	},
	setup() {
		const store = useStore()
		const { enterScreening, exitScreening: _exitScreening } = useScreening()

		const { previewerCurrentTabs } = useDynamicTabs()

		const { isMicroApp } = useQianKun()
		const {
			openMoreResourceDialog,
			noticePptStopPlay
		} = useQianKunEventCenter()
		const { runtime } = useRunTime()
		useGlobalHotkey()

		const {
			renderSlideIndexs,
			specialRenderSlideIndexs,
			pagingSlides
		} = usePagingScrren()

		const {
			animationIndex,
			previousStep,
			nextStep,
			turnSlideToIndex,
			finishAnimation
		} = useTurnPageSlide()

		const {
			disposePptCanvasList,
			handlePencilState,
			sendChangePage,
			sendIsPencilOpen
		} = useGlobalPencil()
		const { sendPenStage, isPenOpened, setSlideScale } = useGlobalPencilNext()

		const { onBrowserWindowMaximizeFullscreenListener } = useIpcRenderer()

		const slideIndex = computed(() => store.state.slideIndex)
		const viewportRatio = computed(() => store.state.viewportRatio)
		const currentSlide = computed<Slide>(() => store.getters.currentSlide)
		const screenType = computed(() => store.state.screenType)
		const screening = computed(() => store.state.screening)
		const isTabs = computed(() => previewerCurrentTabs.value.length)
		const showScreenBtn = computed(() => store.state.showScreenBtn)

		const canShowScreenControls = computed(()=>store.state.showScreenControls)

		const getCurrentSlideTurningMode = (turningMode: string) => {
			if (turningMode === 'random') {
				const _randomSlideAnimations = SLIDE_ANIMATIONS.filter(
					(animation) => animation.value !== 'no'
				)
				const randomIndex = Math.floor(
					Math.random() * _randomSlideAnimations.length
				)
				return _randomSlideAnimations[randomIndex].value
			}
			return turningMode
		}

		const slideWidth = ref(400)
		const slideHeight = ref(300)

		const scale = computed(() => slideWidth.value / VIEWPORT_SIZE)

		// 计算和更新幻灯片内容的尺寸（按比例自适应屏幕）
		const container = ref<HTMLDivElement>()
		const setSlideContentSize = _.debounce(() => {
			logger.info('[setSlideContentSize]-重新计算画布宽高')
			const winWidth = container.value!.clientWidth
			const winHeight = container.value!.clientHeight
			let width, height

			if (winHeight / winWidth === viewportRatio.value) {
				width = winWidth
				height = winHeight
			} else if (winHeight / winWidth > viewportRatio.value) {
				width = winWidth
				height = winWidth * viewportRatio.value
			} else {
				width = winHeight / viewportRatio.value
				height = winHeight
			}
			slideWidth.value = width
			slideHeight.value = height

			if (window.require) {
				sendChangePage()
			}
			setSlideScale(scale.value)
			isPenOpened.value && sendPenStage()
		}, 300)

		// 快捷键翻页
		const keydownListener = (e: KeyboardEvent) => {
			const key = e.key.toUpperCase()
			if (key === KEYS.TAB) {
				e.preventDefault()
				return
			}
			if (key === KEYS.UP || key === KEYS.LEFT || key === KEYS.PAGE_UP) {
				previousStep()
			} else if (
				key === KEYS.DOWN ||
				key === KEYS.RIGHT ||
				key === KEYS.PAGE_DOWN
			) {
				nextStep()
			}
		}

		// 控制栏
		const slideThumbnailModelVisible = reactive({
			type: 'right',
			show: false
		})

		const toggleThumbnails = (leftOrRight: 'left' | 'right') => {
			// slideThumbnailModelVisible.value = !slideThumbnailModelVisible.value;
			// console.log(slideThumbnailModelVisible.value);
			if (leftOrRight === slideThumbnailModelVisible.type) {
				slideThumbnailModelVisible.show = !slideThumbnailModelVisible.show
			} else {
				slideThumbnailModelVisible.show = true
			}
			slideThumbnailModelVisible.type = leftOrRight
		}
		provide('toggleThumbnails', toggleThumbnails)

		const hideThumbnails = () => {
			slideThumbnailModelVisible.show = false
		}

		const startScreening = () => {
			slideThumbnailModelVisible.show = false
			enterScreening()
			// 查询笔的状态
			sendIsPencilOpen()
		}

		const isShowWorkbenchFooter = ref(runtime.isShowWorkbenchFooter)
		const {
			isPowerByTabPage,
			sendToHost,
			receiveHostMessage,
			removeReceiveHostMessage
		} = useTeachingTabPage()

		const startShowHandler = () => {
			if (isPowerByTabPage) {
				isShowWorkbenchFooter.value = false
				sendToHost('SHOW_WORKBENCH_FOOTER', false)
			}
			slideThumbnailModelVisible.show && hideThumbnails()
		}

		const endShowHandler = () => {
			if (isPowerByTabPage) {
				isShowWorkbenchFooter.value = true
				sendToHost('SHOW_WORKBENCH_FOOTER', true)
			}
		}

		const receiveHostMessageHandler = (e: any, ...arg: any[]) => {
			if (!arg[0] && slideThumbnailModelVisible.show) {
				hideThumbnails()
			}
			isShowWorkbenchFooter.value = arg[0]
		}

		const exitScreening = _.throttle(_exitScreening, 1000, {
			leading: true,
			trailing: false
		})
		const finishPlay = () => {
			finishAnimation()
			emitter.emit(EmitterEvents.PPT_STOP_PLAY)
			isMicroApp && noticePptStopPlay()
			exitScreening()
			// 释放画笔数据;
			if (window.require) {
				disposePptCanvasList()
			}
		}

		const hiddenToolBar = ref(false)
		const changeHiddenToolBar = (state?: boolean) => {
			if (state !== undefined) {
				hiddenToolBar.value = state
			} else {
				hiddenToolBar.value = !hiddenToolBar.value
			}
		}

		onMounted(() => {
			// 通知笔组件
			handlePencilState()
			setSlideContentSize()
			onBrowserWindowMaximizeFullscreenListener(setSlideContentSize)
			container.value &&
				(container.value.oncontextmenu = (e) => e.preventDefault())
			document.addEventListener('keydown', keydownListener)
			emitter.on(EmitterEvents.ON_WEBVIEW_TASK_BAR_SHOW, setSlideContentSize)
			if (runtime.playPptRule.showSlideThumbnails) {
				toggleThumbnails('left')
			}
			receiveHostMessage('show-workbench-footer', receiveHostMessageHandler)
		})
		onBeforeUnmount(() => {
			document.removeEventListener('keydown', keydownListener)
			if (window.require) {
				disposePptCanvasList()
			}
			emitter.off(EmitterEvents.ON_WEBVIEW_TASK_BAR_SHOW, setSlideContentSize)

			removeReceiveHostMessage(
				'show-workbench-footer',
				receiveHostMessageHandler
			)
		})
		onUpdated(() => {
			nextTick(() => {
				setSlideContentSize()
				logger.info('[onUpdated]-重新计算画布宽高')
			})
		})

		provide('slideScale', scale)

		return {
			isMicroApp,
			screenType,
			screening,
			slideIndex,
			currentSlide,
			slideWidth,
			slideHeight,
			scale,
			previousStep,
			nextStep,
			runtime,
			slideThumbnailModelVisible,
			turnSlideToIndex,
			container,
			toggleThumbnails,
			hideThumbnails,
			startScreening,
			finishPlay,
			openMoreResourceDialog,
			renderSlideIndexs,
			specialRenderSlideIndexs,
			pagingSlides,
			isTabs,
			animationIndex,
			hiddenToolBar,
			changeHiddenToolBar,
			getCurrentSlideTurningMode,
			isShowWorkbenchFooter,
			startShowHandler,
			endShowHandler,
			showScreenBtn,
			canShowScreenControls
		}
	}
})
