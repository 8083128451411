import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3767b7ba")
const _hoisted_1 = { class: "previewtabs" }
const _hoisted_2 = {
  key: 0,
  class: "second-level"
}
const _hoisted_3 = { class: "second-level-head" }
const _hoisted_4 = {
  key: 0,
  class: "tabs"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "first-level" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.secondToolBarVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.secondLevelTabs.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondLevelTabs, (tab) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["tab", { active: tab.id === _ctx.currentState }]),
                      key: tab.id,
                      onClick: ($event: any) => (_ctx.setCurrentTab(tab.id))
                    }, _toDisplayString(tab.name), 11, _hoisted_5))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "second-level-close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeSecondToolBarVisible())),
              style: _normalizeStyle(
						_ctx.secondLevelTabs.length > 0
							? 'border-left: 1px solid rgb(238, 238, 238)'
							: ''
					)
            }, [
              _createVNode(_component_CloseOutlined)
            ], 4)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(), _createBlock(_KeepAlive, { include: _ctx.keepAliveTabs }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTab), {
                ref: "tabContent",
                onMountCloseSecondToolBar: _ctx.onMountCloseSecondToolBar
              }, null, 8, ["onMountCloseSecondToolBar"]))
            ], 1032, ["include"]))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstLevelTabs, (fTab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "first-level-tab",
          key: fTab.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["first-level-item", 
						fTab.id === _ctx.currentFirstTabId ? 'first-level-tab-selected' : ''
					]),
            onClick: ($event: any) => (_ctx.changeFirstTab(fTab.id))
          }, [
            _createElementVNode("img", {
              class: "first-level-tab-icon",
              src: 
							fTab.id === _ctx.currentFirstTabId
								? require(`@/assets/images/toolbar/${fTab.tabType}-selected.png`)
								: require(`@/assets/images/toolbar/${fTab.tabType}.png`)
						
            }, null, 8, _hoisted_9),
            _createElementVNode("div", null, _toDisplayString(fTab.name), 1)
          ], 10, _hoisted_8)
        ]))
      }), 128))
    ])
  ]))
}