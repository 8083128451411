import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7577c0e0")
const _hoisted_1 = { class: "container" }
_popScopeId()




export default _defineComponent({
  props: {
  text: {
    default: '结束放映',
    type: String
  }
},
  setup(__props) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", null, _toDisplayString(props.text), 1)
  ]))
}
}

})