import {
	ANIMATION_CLASS_PREFIX,
	ANIMATION_NEXT_STEP_EVENTS,
	GlobalAnimations
} from '@/configs/animation'
import useQianKun from '@/hooks/useQianKun'
import useQianKunEventCenter from '@/hooks/useQianKunEventCenter'
import useRunTime from '@/hooks/useRunTime'
import useShowMessage from '@/hooks/useShowMessage'
import { MutationTypes, useStore } from '@/store'
import { FormatedAnimation, Slide, TriggeredAnimation } from '@/types/slides'
import emitter, { EmitterEvents } from '@/utils/emitter'
import _ from 'lodash'
import { computed, ref } from 'vue'
import { runCustomAnimation } from '@/utils/animation'

interface RunningAnimation {
	targetId: string
	group?: string
	animationId: string
	timeId?: any
	delayTimeId?: any
	animationName?: string
	endX?: number
	endY?: number
	runTimesIndex?: number
}

let triggeredAnimations: TriggeredAnimation = {}

export default () => {
	const store = useStore()
	const { showMeassage } = useShowMessage()
	const { isMicroApp } = useQianKun()
	const { runtime } = useRunTime()
	const { noticeLoadPreviousOrNextPPt } = useQianKunEventCenter()

	const slides = computed(() => store.state.slides)
	const slideIndex = computed(() => store.state.slideIndex)
	const currentSlide = computed<Slide>(() => store.getters.currentSlide)
	const animations = computed(() => currentSlide.value.animations || [])
	// const formatedAnimations = computed(() => store.getters.formatedAnimations)

	const animationIndex = computed(() => store.state.animationIndex)
	const loadPNPPTSign = computed(() => store.state.loadPNPPTSign)

	// 当前正在播放的动画
	const currentRunningAnimations = ref<RunningAnimation[]>([])

	const updateTriggeredAnimations = (
		newTriggeredAnimations: TriggeredAnimation
	) => {
		triggeredAnimations = newTriggeredAnimations
	}

	// let nextStepIndex = 0
	// 重组 nextStepEvvnts 数据
	const transform = (next: any) => {
		if (!Array.isArray(next)) {
			next = [next]
		}
		next = next.map((item: any) => {
			if (typeof item === 'string') {
				item = {
					command: item
				}
			}
			return item
		})
		return next
	}
	// 移除播放完毕的动画
	const removeRunningAnimation = (animationId: string) => {
		const _runningAnimations = currentRunningAnimations.value.filter(
			(running) => running.animationId !== animationId
		)
		currentRunningAnimations.value.length = 0
		currentRunningAnimations.value.push(..._runningAnimations)
	}
	// 移除播放完毕的动画style
	const removeAnimationStyle = (_elRef: HTMLElement) => {
		if (!_elRef) {
			return
		}
		_elRef.style.removeProperty('--animate-duration')
		_elRef.style.removeProperty('animation-delay')
		_elRef.style.removeProperty('animation-iteration-count')
		_elRef.style.removeProperty('animation-name');
		_elRef.style.removeProperty('animation-timing-function');
		_elRef.style.removeProperty('animation-duration');
	}
	// 停止正在播放的动画
	const stopRunningAnimation = (
		runningAnimation: RunningAnimation,
		stopAll = false,
		forceRemove = false
	) => {
		const readyStopAnimations = stopAll
			? currentRunningAnimations.value
			: [runningAnimation]
		readyStopAnimations.forEach((stopAnimation: RunningAnimation) => {
			const elRef: HTMLElement | null = document.querySelector(
				`#screen-element-${stopAnimation.targetId} [class^=base-element-]`
			)
			if (!elRef) {
				return
			}
			removeRunningAnimation(stopAnimation.animationId)
			const currentAnimationAudio = document.getElementById(
				`animation_audio_${stopAnimation.animationId}`
			)
			currentAnimationAudio && elRef.removeChild(currentAnimationAudio)

			if (stopAnimation?.timeId) {
				clearTimeout(stopAnimation.timeId)
			}
			if (stopAnimation?.timeId) {
				const { endX, endY } = stopAnimation || {}
				;(elRef as HTMLElement).style.setProperty(
					'transform',
					`translate(${endX}px,${endY}px)`
				)
				window.cancelAnimationFrame(stopAnimation.timeId)
			}
			if (stopAnimation?.animationName) {
				const _elRef = elRef.getElementsByClassName(
					'element-content'
				)[0] as HTMLElement
				if (!_elRef) {
					return
				}
				if (stopAnimation?.group !== 'out' || forceRemove) {
					removeAnimationStyle(_elRef)
					for (const classname of _elRef.classList) {
						if (classname.indexOf(ANIMATION_CLASS_PREFIX) !== -1) {
							_elRef.classList.remove(
								classname,
								`${ANIMATION_CLASS_PREFIX}animated`
							)
						}
					}
				}
			}
		})
	}

	// 执行元素的入场动画
	const runAnimation = (triggerSource?: string) => {
		// 正在执行动画时，禁止其他新的动画开始
		const _formatedAnimations: FormatedAnimation = {
			animations: [],
			autoNext: false
		}

		// 判断动画触发源
		const _triggerSource = triggeredAnimations?.[triggerSource || 'next_page']
		if (_triggerSource) {
			_.merge(
				_formatedAnimations,
				_triggerSource.formatedAnimation[_triggerSource.animationIndex]
			)
		}

		const { animations, autoNext } = _formatedAnimations
		if (animations.length === 0) {
			return
		}
		triggeredAnimations[triggerSource || 'next_page'].animationIndex++
		let endAnimationCount = 0

		// 依次执行该位置中的全部动画
		for (const animation of animations) {
			const elRef: HTMLElement | null = document.querySelector(
				`#screen-element-${animation.elId} [class^=base-element-]`
			)
			if (!elRef) {
				endAnimationCount += 1
				continue
			}
			const _elRef = elRef.getElementsByClassName(
				'element-content'
			)[0] as HTMLElement
			if (!_elRef) {
				endAnimationCount += 1
				continue
			}
			// 先停止目标元素正在播放的动画
			const isRunningAnimation = currentRunningAnimations.value.find(
				(running) => running.targetId === animation.elId
			)
			if (isRunningAnimation) {
				// 若元素上一个动画是退场，需要移除样式
				const forceRemove = isRunningAnimation.targetId === animation.elId
				stopRunningAnimation(isRunningAnimation, false, forceRemove)
			}

			const animationName = `${ANIMATION_CLASS_PREFIX}${animation.type}`

			// 执行动画音频
			if (animation.audio) {
				const appendedAudio = document.createElement('audio')
				appendedAudio.src = require(`@/assets/audios/${animation.audio}.wav`)
				appendedAudio.id = `animation_audio_${animation.id}`
				// 等元素动画开始后，再播放音频
				setTimeout(() => {
					appendedAudio.play()
				}, animation.delay)
				elRef.appendChild(appendedAudio)
			}

			const seCustomAnimationTimeId = (timeId: any) => {
				const runningAnimationIndex = currentRunningAnimations.value.findIndex(
					(running) => running.animationId === animation.id
				)
				if (runningAnimationIndex >= 0) {
					currentRunningAnimations.value[runningAnimationIndex][
						'timeId'
					] = timeId
				}
			}

			// 执行动画
			if (animation.type === 'custom') {
				const delayTimeId = setTimeout(() => {
					runCustomAnimation(
						elRef,
						animation,
						true,
						seCustomAnimationTimeId
					).then(() => {
						// 自定义路径动画播放结束
						removeRunningAnimation(animation.id)
						// 播放完自定义动画后，判断下一个动画是否需要自动播放
						endAnimationCount += 1
						if (endAnimationCount === animations.length) {
							if (autoNext) runAnimation()
						}
					})
				}, animation.delay)
				const { points } = animation.path || {}
				currentRunningAnimations.value.push({
					targetId: animation.elId,
					group: animation.group,
					animationId: animation.id,
					delayTimeId,
					endX: points[points?.length - 1][0] || 0,
					endY: points[points?.length - 1][1] || 0,
					runTimesIndex: 0
				})
			} else if (animation.type === ANIMATION_NEXT_STEP_EVENTS) {
				const currentEl = _.cloneDeep(
					currentSlide.value.elements.find((el) => el.id === animation.elId)
				)
				if (!currentEl) {
					return
				}
				// 数据重组
				currentEl.nextStepEvents = transform(currentEl.nextStepEvents)
				emitter.emit(EmitterEvents.NEXT_STEP, {
					command: currentEl.nextStepEvents,
					slideId: currentSlide.value.id,
					elementId: currentEl.id
				})
				store.commit(MutationTypes.SET_NEXT_ACTION_PLAYED_IDS, [
					...store.state.nextActionPlayedIds,
					currentEl.id
				])
			} else {
				currentRunningAnimations.value.push({
					targetId: animation.elId,
					group: animation.group,
					animationId: animation.id,
					animationName
				})
				_elRef.style.setProperty('animation-delay', `${animation.delay}ms `)
				_elRef.style.setProperty(
					'animation-iteration-count',
					`${animation.runTimes} `
				)
				if (GlobalAnimations.includes(animationName)) {
					_elRef.style.setProperty('animation-name', `${animationName}`);
					_elRef.style.setProperty('animation-timing-function', `${'linear'}`);
					_elRef.style.setProperty('animation-duration', `${animation.duration}ms`);
				} else {
					_elRef.style.setProperty(
						'--animate-duration',
						`${animation.duration}ms`
					)
					_elRef.classList.add(animationName, `${ANIMATION_CLASS_PREFIX}animated`)
				}

			}

			// 执行动画结束，将“退场”以外的动画状态清除
			const handleAnimationEnd = () => {
				const isExitAnimation = currentRunningAnimations.value.find(
					(running) => running.animationId === animation.id
				)
				if (!isExitAnimation) {
					return
				}
				if (animation.group !== 'out') {
					removeAnimationStyle(_elRef)
					_elRef.classList.remove(
						animationName,
						`${ANIMATION_CLASS_PREFIX}animated`
					)
					removeRunningAnimation(animation.id)
				}
				const currentAnimationAudio = document.getElementById(
					`animation_audio_${animation.id}`
				)
				currentAnimationAudio && elRef.removeChild(currentAnimationAudio)

				// 判断该位置上的全部动画都已经结束后，标记动画执行完成，并尝试继续向下执行（如果有需要）
				endAnimationCount += 1
				if (endAnimationCount === animations.length) {
					if (autoNext) {
						runAnimation()
					}
				}
			}
			_elRef.addEventListener('animationend', handleAnimationEnd, {
				once: true
			})
		}
	}
	// 撤销元素动画，除了将索引前移外，还需要清除动画状态
	const revokeAnimation = () => {
		if (!triggeredAnimations['next_page']) {
			return
		}
		triggeredAnimations['next_page'].animationIndex--
		const formatedAnimation =
			triggeredAnimations['next_page']?.formatedAnimation
		const animationIndex = triggeredAnimations['next_page']?.animationIndex
		const { animations } = formatedAnimation[animationIndex]

		for (const animation of animations) {
			const elRef: HTMLElement | null = document.querySelector(
				`#screen-element-${animation.elId} [class^=base-element-]`
			)
			if (!elRef) continue

			elRef.style.removeProperty('transition')
			elRef.style.removeProperty('transform')

			const currentAnimationAudio = document.getElementById(
				`animation_audio_${animation.id}`
			)
			currentAnimationAudio && elRef.removeChild(currentAnimationAudio)

			const _elRef = elRef.getElementsByClassName(
				'element-content'
			)[0] as HTMLElement

			if (!_elRef) continue
			removeAnimationStyle(_elRef)
			for (const classname of _elRef.classList) {
				if (classname.indexOf(ANIMATION_CLASS_PREFIX) !== -1) {
					_elRef.classList.remove(
						classname,
						`${ANIMATION_CLASS_PREFIX}animated`
					)
				}
			}
		}

		// 如果撤销时该位置有且仅有强调动画，则继续执行一次撤销
		// if (animations.every((item: any) => item.group === 'attention')) {
		// 	previousStep()
		// }
	}

	const previousStep = () => {
		const animationIndex =
			triggeredAnimations?.['next_page']?.animationIndex || 0
		if (animations.value.length && animationIndex > 0) {
			revokeAnimation()
		} else if (slideIndex.value > 0) {
			// 切换上下页停止正在播放的所有动画
			stopRunningAnimation({ targetId: '', animationId: '' }, true)

			store.commit(MutationTypes.UPDATE_SLIDE_INDEX, slideIndex.value - 1)
			const lastIndex = animations.value ? animations.value.length : 0
			store.commit(MutationTypes.SET_ANIMATION_INDEX, lastIndex)
			runtime.autoLoadPNPpt &&
				store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
			emitter.emit(EmitterEvents.SWITCH_SLIDE_PAGE)
		} else {
			if (runtime.autoLoadPNPpt) {
				if (loadPNPPTSign.value) {
					if (isMicroApp) {
						noticeLoadPreviousOrNextPPt('previous')
					}
					store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
					return
				}
				showMeassage('已经是第一页了,再次点击跳转到上一课时')
				store.commit(MutationTypes.SET_LOADPNPPTSIGN, true)
			} else {
				showMeassage('已经是第一页了')
			}
		}
	}

	const nextStep = () => {
		const _triggerAnimation = triggeredAnimations?.['next_page']
		if (
			_triggerAnimation?.formatedAnimation?.length &&
			_triggerAnimation?.animationIndex <
				_triggerAnimation?.formatedAnimation?.length
		) {
			runAnimation()
		} else if (slideIndex.value < slides.value.length - 1) {
			// 切换上下页停止正在播放的所有动画
			stopRunningAnimation({ targetId: '', animationId: '' }, true)

			store.commit(MutationTypes.SET_NEXT_ACTION_STEP_INDEX, 0)
			store.commit(MutationTypes.SET_NEXT_ACTION_PLAYED_IDS, [])

			store.commit(MutationTypes.UPDATE_SLIDE_INDEX, store.state.slideIndex + 1)
			store.commit(MutationTypes.SET_ANIMATION_INDEX, 0)
			runtime.autoLoadPNPpt &&
				store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
			emitter.emit(EmitterEvents.SWITCH_SLIDE_PAGE)
		} else {
			if (runtime.autoLoadPNPpt) {
				if (loadPNPPTSign.value) {
					if (isMicroApp) {
						noticeLoadPreviousOrNextPPt('next')
					}
					store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
					return
				}
				showMeassage('已经是最后一页了,再次点击跳转到下一课时')
				store.commit(MutationTypes.SET_LOADPNPPTSIGN, true)
			} else {
				// nextStepIndex = 0
				store.commit(MutationTypes.SET_NEXT_ACTION_STEP_INDEX, 0)
				store.commit(MutationTypes.SET_NEXT_ACTION_PLAYED_IDS, [])
				showMeassage('已经是最后一页了')
			}
		}
	}

	const previousSlide = () => {
		if (slideIndex.value - 1 < 0) {
			if (runtime.autoLoadPNPpt) {
				if (loadPNPPTSign.value) {
					if (isMicroApp) {
						noticeLoadPreviousOrNextPPt('previous')
					}
					store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
					return
				}
				showMeassage('已经是第一页了,再次点击跳转到上一课时')
				store.commit(MutationTypes.SET_LOADPNPPTSIGN, true)
				return
			}
			showMeassage('已经是第一页了')
			return
		}
		turnSlideToIndex(slideIndex.value - 1)
		runtime.autoLoadPNPpt &&
			store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
	}

	const nextSlide = () => {
		if (slideIndex.value + 1 >= slides.value.length) {
			if (runtime.autoLoadPNPpt) {
				if (loadPNPPTSign.value) {
					if (isMicroApp) {
						// 作为子应用，注册外部加载下一课时ppt，加载知识点
						noticeLoadPreviousOrNextPPt('next')
					}
					store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
					return
				}
				showMeassage('已经是最后一页了,再次点击跳转到下一课时')
				store.commit(MutationTypes.SET_LOADPNPPTSIGN, true)
				return
			}
			showMeassage('已经是最后一页了')
			return
		}
		turnSlideToIndex(slideIndex.value + 1)
		runtime.autoLoadPNPpt &&
			store.commit(MutationTypes.SET_LOADPNPPTSIGN, false)
	}

	const turnSlideToIndex = (index: number) => {
		store.commit(MutationTypes.UPDATE_SLIDE_INDEX, index)
		emitter.emit(EmitterEvents.SWITCH_SLIDE_PAGE)
		store.commit(MutationTypes.SET_ANIMATION_INDEX, 0)
		store.commit(MutationTypes.SET_NEXT_ACTION_STEP_INDEX, 0)
		store.commit(MutationTypes.SET_NEXT_ACTION_PLAYED_IDS, [])
	}
	const finishAnimation = () => {
		store.commit(MutationTypes.SET_ANIMATION_INDEX, 0)
	}

	return {
		animationIndex,
		runAnimation,
		previousStep,
		nextStep,
		previousSlide,
		nextSlide,
		turnSlideToIndex,
		finishAnimation,
		updateTriggeredAnimations,
		triggeredAnimations
	}
}
