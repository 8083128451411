import { computed, ref, watch } from 'vue'
import { ElfElementTypes } from '@/types/slides'
import { useStore } from '@/store'
import useRunTime from '@/hooks/useRunTime'

export default () => {
	const store = useStore()
	const { runtime } = useRunTime()

	const uuid = computed(() => store.state.uuid)
	const slides = computed(() => store.state.slides)
	const slideIndex = computed(() => store.state.slideIndex)

	const renderSlideStep = computed(() => runtime.pagingScreenNum) // 可渲染的幻灯片步长
	const renderSlideIndexs = ref<number[]>([]) // 可渲染的幻灯片编号数组
	const specialRenderSlideIndexs = ref<number[]>([]) // 特殊不可销毁的幻灯片编号数组,有元素为钢琴合奏的PPT不可销毁

	const specialElementType: any[] = [ElfElementTypes.ENSEMBLE_PRACTICE]

	const calculateSPecialRenderSlideIndexs = () => {
		specialRenderSlideIndexs.value.length = 0
		slides.value.forEach((slide, index) => {
			if (
				slide.elements.some(
					(element: any) =>
						specialElementType.includes(element.type) ||
						specialElementType.includes(element?.subtype)
				)
			) {
				specialRenderSlideIndexs.value.push(index)
			}
		})
	}

	const calculateRenderSlideIndexs = () => {
		renderSlideIndexs.value.length = 0
		const currentIndex = slideIndex.value || 0
		for (
			let i = currentIndex - renderSlideStep.value;
			i <= currentIndex + renderSlideStep.value;
			i++
		) {
			if (i < 0) {
				continue
			}
			if (i >= slides.value.length) {
				break
			}
			renderSlideIndexs.value.push(i)
		}
	}

	watch(() => slideIndex.value, calculateRenderSlideIndexs, {
		immediate: true
	})
	watch(
		() => uuid.value,
		() => {
			calculateSPecialRenderSlideIndexs()
			calculateRenderSlideIndexs()
		},
		{ immediate: true }
	)

	const pagingSlides = computed(() => {
		return slides.value.map((slide) => {
			return {
				...slide
			}
		})
	})

	return {
		renderSlideIndexs,
		specialRenderSlideIndexs,
		pagingSlides
	}
}
