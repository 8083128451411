
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import useDynamicTabs from '@/hooks/useDynamicTabs'
import { ToolbarStates } from '@/types/toolbar'
import LessonPlanPanel from '../components/dynamicTabs/LessonPlanPanel.vue'
import SpeakLessonPanel from '../components/dynamicTabs/SpeakLessonPanel.vue'
import { ToolBarTab } from '@/configs/toolbar'

const panelMap = {
	[ToolbarStates.LESSON_PLAN]: LessonPlanPanel,
	[ToolbarStates.SPEAK_LESSON]: SpeakLessonPanel
}
// 类型与组件名称映射
const compNameMap = {
	lessonPlan: 'lessonPlan-panel',
	speaklesson: 'speakLesson-panel'
}

export default defineComponent({
	name: 'preview-tabs',
	components: { CloseOutlined },
	props: {
		hiddenToolBar: {
			type: Boolean,
			default: false
		},
		changeHiddenToolBar: {
			type: Function
		}
	},
	setup(props) {
		const { previewerCurrentTabs } = useDynamicTabs()

		const currentState = ref<any>()
		const currentFirstTabId = ref()
		const keepAliveTabs: string[] = [] // 保持状态的组件名称
		const secondToolBarVisible = computed(() => !props.hiddenToolBar)
		const tabContent = ref<any>(null)

		const tabs = computed(() => {
			previewerCurrentTabs.value.forEach((tab: ToolBarTab) => {
				if (panelMap[tab.tabType]) {
					tab.comp = panelMap[tab.tabType]
				}
				if (tab.keepAlive && compNameMap[tab.tabType]) {
					keepAliveTabs.push(compNameMap[tab.tabType])
				}
			})
			return previewerCurrentTabs.value
		})

		const setCurrentTab = (value: number) => {
			currentState.value = value
		}
		// 一级功能
		const firstLevelTabs = computed(() => {
			return tabs.value?.filter((tab: ToolBarTab) => tab.parentId === 0) || []
		})
		// 二级功能
		const secondLevelTabs = computed(() => {
			if (currentFirstTabId.value !== undefined) {
				return tabs.value.filter(
					(tab: ToolBarTab) => tab.parentId === currentFirstTabId.value
				)
			}
			return []
		})

		const currentTab = computed(() => {
			// 一级功能中无内容组件表示有二级功能，再从二级功能中找内容组件
			return (
				firstLevelTabs.value.find(
					(tab: ToolBarTab) => tab.id === currentFirstTabId.value
				)?.comp ||
				secondLevelTabs.value.find(
					(tab: ToolBarTab) => tab.id === currentState.value
				)?.comp ||
				null
			)
		})

		const changeFirstTab = (selectedId: number) => {
			if (currentFirstTabId.value === selectedId) {
				changeSecondToolBarVisible()
			} else {
				changeSecondToolBarVisible(true)
			}
			currentFirstTabId.value = selectedId
		}

		const changeSecondToolBarVisible = (state?: boolean) => {
			if (state !== undefined) {
				props.changeHiddenToolBar && props.changeHiddenToolBar(!state)
			} else {
				props.changeHiddenToolBar && props.changeHiddenToolBar()
			}
		}

		// 开始时，若右侧面板当前一级功能下无数据，则收起二级面板
		let isMountJust = false
		const onMountCloseSecondToolBar = (dataExit: boolean) => {
			if (isMountJust) {
				if (!dataExit) {
					changeSecondToolBarVisible(false)
				}
				isMountJust = false
			}
		}

		watch(
			() => secondToolBarVisible.value,
			() => {
				if (!secondToolBarVisible.value) {
					currentFirstTabId.value = undefined
				}
			},
			{ immediate: true }
		)

		onMounted(() => {
			currentFirstTabId.value = firstLevelTabs.value.find(
				(tab: ToolBarTab) => tab.state
			)?.id
			if (!currentFirstTabId.value && firstLevelTabs.value[0]) {
				currentFirstTabId.value = firstLevelTabs.value[0].id
			}
			isMountJust = true
		})
		return {
			tabContent,
			firstLevelTabs,
			changeFirstTab,
			currentFirstTabId,
			secondToolBarVisible,
			changeSecondToolBarVisible,
			onMountCloseSecondToolBar,
			keepAliveTabs,
			secondLevelTabs,
			previewerCurrentTabs,
			currentState,
			currentTab,
			setCurrentTab
		}
	}
})
