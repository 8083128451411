// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/left-hl.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/right.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/right-hl.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".container[data-v-ac26cc40]{display:flex;width:138px;height:46px;border-radius:25px;background-color:hsla(0,0%,100%,.24);padding:2px;box-sizing:content-box;position:relative;border-radius:16px;padding:1px 2px;box-shadow:0 2px 6px 0 rgba(71,85,91,.5)}.container[data-v-ac26cc40]:hover{cursor:pointer}.page-num[data-v-ac26cc40]{margin:0 2px;display:flex;justify-content:center;align-items:center;flex-grow:1;font-size:14px;flex-direction:column;height:100%;background-color:rgba(0,0,0,.24);font-size:22px;color:#fff;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.page-num span[data-v-ac26cc40]{font-size:15px}.left[data-v-ac26cc40]{border-radius:16px 0 0 16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:40px;background-position:50%;background-repeat:no-repeat;border:none;height:100%;background-color:rgba(0,0,0,.24);font-size:22px;color:#fff;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}.left[data-v-ac26cc40]:active{background-color:rgba(0,0,0,.12);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.left .left-hot-area[data-v-ac26cc40]{position:absolute;height:100px;top:-50%;left:-10%}.right[data-v-ac26cc40]{border-radius:0 16px 16px 0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");width:40px;background-position:50%;background-repeat:no-repeat;border:none;height:100%;background-color:rgba(0,0,0,.24);font-size:22px;color:#fff;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}.right[data-v-ac26cc40]:active{background-color:rgba(0,0,0,.12);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.right .right-hot-area[data-v-ac26cc40]{position:absolute;height:100px;top:-50%;right:-10%}", ""]);
// Exports
module.exports = exports;
