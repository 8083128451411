import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-29511a44")
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "thumbnail-subscript" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailSlide = _resolveComponent("ThumbnailSlide")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slide-thumbnails", [
			_ctx.screenType === 'preview'
				? 'slide-thumbnails-preview'
				: 'slide-thumbnails-demonstrate'
		]]),
    ref: "scrollEl",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelClick && _ctx.cancelClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
				_ctx.screenType === 'preview'
					? 'scroll-content'
					: 'scroll-content-demonstrate-left'
			])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["thumbnail", { active: index === _ctx.slideIndex }]),
          ref: index === _ctx.slideIndex ? 'activeThumbnailSlide' : '',
          key: slide.id,
          onClick: ($event: any) => (_ctx.turnSlideToIndex(index))
        }, [
          _createVNode(_component_ThumbnailSlide, {
            key: slide.id,
            slideIndex: index,
            slide: slide,
            size: 275,
            isImportPptx: _ctx.isImportPptx
          }, null, 8, ["slideIndex", "slide", "isImportPptx"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, _toDisplayString(index + 1) + " / " + _toDisplayString(_ctx.totalSlideNum), 1)
          ])
        ], 10, _hoisted_1))
      }), 128))
    ], 2)
  ], 2))
}