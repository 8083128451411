
import { defineComponent, computed, inject } from 'vue'
import { useStore } from '@/store'
import useTurnPageSlide from './hooks/useTurnPageSlide'

export default defineComponent({
    name: 'page-button',
    props: {
        leftOrRight: {
            type: String
        }
    },
    setup(props) {
        const store = useStore()
        const { previousStep, nextStep } = useTurnPageSlide()

        const totalSlideNum = computed(() => store.state.slides.length)
        const curSlideNum = computed(() => store.state.slideIndex + 1)
        const toggleThumbnails = inject('toggleThumbnails') as any

        const update = () => {
            toggleThumbnails(props.leftOrRight)
        }
        return {
            totalSlideNum,
            curSlideNum,
            nextStep,
            previousStep,
            update,
            toggleThumbnails
        }
    }
})
